@import "../components/_team-slider";

section.team {
  // background-color: var(--white);
  height: 100vh;
  padding: 100px 0;
  align-items: flex-start;
  user-select: none;

  .container {
    @include grid(12, min-max(16, 24));
    padding: 0 min-max(24, 60);
    transform: translateY(50%);

    @include mq-max-tablet {
      transform: translateY(5%);
      @include flex(column);
    }

    @include mq-max-phone {
      align-items: flex-start;
    }
  }

  .section-gallery {
    grid-area: 1 / 4 / 2 / 7;
    width: min-max(240, 310);
    // width: min-max(263, 311);
    aspect-ratio: 263/373;
    display: grid;
    transform: translateX(-40%) scale(1.7);

    &.top-hidden {
      .top-gallery {
        opacity: 0;
      }
    }

    @include mq-max-tablet {
      width: min-max(220, 310);
      transform: translateY(-10%);
    }

    .item {
      grid-column: 1;
      grid-row: 1;
      transform-origin: 80% 80%;

      &.top-gallery {
        pointer-events: none;
        transition: opacity var(--transition);
      }

      &:nth-of-type(4) {
        transform: rotate(22deg) translate(7.5%, -7.5%);
        z-index: 1;

        @include mq-max-tablet {
          transform: rotate(22deg);
        }
      }

      &:nth-of-type(3) {
        z-index: 2;
        transform: rotate(11deg) translate(5%, -5%);

        @include mq-max-tablet {
          transform: rotate(11deg);
        }
      }

      &:nth-of-type(2) {
        z-index: 3;
      }
    }

    // slider-x
    @include team-slider;
  }

  .section-content {
    grid-area: 1 / 9 / 2 / 12;
    @include flex(column, min-max(16, 24));
    justify-content: center;
  }

  .section-title {
    @include use-font(var(--gothamMedium), min-max(40, 72), 500, 120%);
    color: var(--dark);
    text-transform: uppercase;
  }

  .section-subtitle {
    width: 90%;

    @include mq-max-tablet {
      width: auto;
    }
  }

  .slider-controls {
    position: fixed;
    width: 100%;
    max-width: 1920px;
    left: 0;
    right: 0;
    bottom: min-max(24, 60);
    padding: 0 min-max(24, 160);
    @include flex(row);
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    transform: translateY(25vh);
    transition: var(--transition-slow);

    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    @include mq-max-tablet {
      display: none;
    }

    .arrows {
      @include flex(row, min-max(8, 16));
    }

    .slider-title {
      @include use-font(var(--gothamMedium), min-max(24, 96), 500);
      text-transform: uppercase;
      color: var(--silver-grey);
    }
  }
}
