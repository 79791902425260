section.services {
  height: 100vh;
  overflow: hidden;

  @include mq-max-tablet {
    height: auto;
  }

  .container {
    height: 100%;
    display: grid;
    grid-template-columns: 17.78% 28.71% 43.53%;
    grid-column-gap: min-max(40, 96);
    grid-row-gap: min-max(24, 40);

    @include mq-max-tablet {
      padding: 88px 65px 0;
      @include flex(column, 40px);
      align-items: center;
    }
  }

  .section-slider {
    height: 100%;
    display: grid;
    align-content: end;

    @include mq-max-tablet {
      height: auto;
    }

    .swiper {
      overflow-y: visible;
      overflow-x: clip;

      @include mq-max-tablet {
        overflow-y: clip;
      }

      &-slide {
        user-select: none;
        transition: 0.3s ease;
      }
    }

    &--small {
      grid-area: 1 / 1 / 4 / 2;
      align-content: end;

      @include mq-max-tablet {
        display: none;
      }

      .swiper {
        height: min-max(341, 418);
        width: 100%;
        aspect-ratio: 342/418;

        &-slide {
          width: 100%;
          aspect-ratio: 342/418;
        }
      }
    }

    &--large {
      grid-area: 1 / 2 / 3 / 3;

      .swiper {
        aspect-ratio: 552/774;
        height: min-max(341, 774);
        width: 100%;

        &-slide {
          width: 100%;
          aspect-ratio: 552/774;
        }
      }

      @include mq-max-tablet {
        width: 100%;
        height: auto;

        .swiper {
          overflow: hidden;
        }
      }
    }
  }

  .section-list {
    grid-area: 1 / 3 / 3 / 4;
    display: grid;
    align-content: end;

    .slider-toggles {
      @include flex(column, 16px);
      width: max-content;

      .item {
        @include custom-button;
        @include flex(column, 8px);
        @include use-font(var(--gothamMedium), min-max(24, 60), 500);
        text-transform: uppercase;
        text-align: left;
        color: var(--light-grey);
        transition: color var(--transition);

        &-line {
          width: 100%;
          height: 1px;
          background-color: var(--silver-grey);
          position: relative;
          opacity: 0;
          transition: opacity var(--transition);

          @include mq-max-tablet {
            display: none;
          }

          &__track {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: var(--dark);
            transform-origin: left;
            transform: scaleX(0%);
            // transition: transform var(--transition);
          }
        }

        &:hover,
        &.active {
          color: var(--dark);
          .item-line {
            opacity: 1;
          }
        }
      }
    }
  }

  .section-button {
    &--top {
      position: absolute;
      right: min-max(64, 96);
      top: min-max(64, 96);
      z-index: 2;

      @include mq-max-tablet {
        display: none;
      }
    }

    &--bottom {
      grid-area: 3 / 3 / 4 / 4;
      padding-bottom: min-max(24, 60);
      align-self: flex-start;
    }
  }
}
