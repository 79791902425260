section.about {
  // background-color: var(--bg);

  .container {
    padding: min-max(48, 200) min-max(32, 160);

    @include mq-max-phone {
      padding: 104px 24px 76px;
    }
  }

  .grid {
    height: min-max(1396, 2645);
    display: grid;
    // grid-template-columns: px2per(1600, 326) px2per(1600, 389) px2per(1600, 143) px2per(1600, 390) px2per(1600, 144) px2per(1600, 208);
    // grid-template-rows: px2per(2465, 221) px2per(2465, 267) px2per(2465, 140) px2per(2465, 521) px2per(2465, 192) px2per(2465, 148) px2per(2465, 260) px2per(2465, 128) px2per(2465, 306) px2per(2465, 282);
    grid-template-columns: 20.375% 24.3125% 8.9375% 24.375% 9% 13%;
    grid-template-rows: 8.963% 10.836% 5.678% 21.135% 7.787% 6.002% 10.547% 5.192% 12.415% 11.445%;

    @include mq-max-phone {
      grid-template-columns: repeat(8, 1fr);
      // grid-template-rows: 80px 80px 48px 95px 57px 315px 48px 95px 48px 81px 76px 56px 261px 56px;
      grid-template-rows: 5.73% 5.73% 3.44% 6.81% 4.08% 22.56% 3.44% 6.81% 3.44% 5.8% 5.44% 4.01% 18.7% 4.01%;
      column-gap: 8px;
    }

    .img-wrapper {
      width: 100%;
      height: 100%;
      @include cover-img;
    }
  }

  .section-heading {
    grid-area: 1 / 1 / 2 / 3;
    display: flex;

    @include mq-max-phone {
      grid-area: 1 / 1 / 2 / 6;
    }

    div {
      @include flex(column, 8px);
      align-items: center;
    }
  }

  .section-title {
    color: var(--dark);
    @include use-font(
      var(--gothamBold),
      min-max(24, 80),
      700,
      100%,
      min-max(7, 24)
    );
  }

  .section-subtitle {
    @include use-font(var(--gothamBold), min-max(6, 12), 700, 160%, 0.15em);
    color: var(--mid-grey);
    width: 40%;
    text-align: center;
  }

  .img-big {
    &.cell-top {
      grid-area: 2 / 1 / 5 / 3;

      @include mq-max-phone {
        grid-area: 2 / 1 / 6 / 5;
        margin-left: -24px;
      }
    }

    &.cell-bottom {
      grid-area: 6 / 4 / 10 / 6;

      @include mq-max-tablet-md {
        grid-area: 6/4/10/7;
      }

      @include mq-max-phone {
        grid-area: 7 / 5 / 11 / 9;
        margin-right: -24px;
      }
    }
  }

  .img-middle {
    &.cell-top {
      grid-area: 1 / 4 / 3 / 5;

      @include mq-max-phone {
        grid-area: 1 / 6 / 3 / 9;
        margin-right: -24px;
      }
    }

    &.cell-bottom {
      grid-area: 9 / 2 / 11 / 3;

      @include mq-max-tablet {
        grid-area: 10/2/11/3;
      }

      @include mq-max-phone {
        grid-area: 10 / 1 / 13 / 4;
        margin-left: -24px;
      }

      .img-wrapper {
        aspect-ratio: 390/588;
      }
    }
  }

  .img-small {
    &.cell-top {
      grid-area: 4 / 6 / 5 / 7;

      .img-wrapper {
        height: auto;
      }

      @include mq-max-phone {
        grid-area: 4 / 6 / 5 / 8;
      }
    }

    &.cell-bottom {
      grid-area: 7 / 1 / 8 / 2;
      padding-right: 30%;

      @include mq-max-phone {
        grid-area: 8 / 2 / 9 / 4;
        padding-right: 0;
      }
    }

    .img-wrapper {
      aspect-ratio: 208/260;
      // height: auto;
    }
  }

  .text-cell {
    @include flex(column, 24px);
    transform: translateY(-3.8em);

    &.cell-top {
      grid-area: 4 / 4 / 5 / 5;

      @include mq-max-phone {
        grid-area: 6 / 2 / 7 / 8;
        justify-content: center;
        transform: none;
      }
    }

    &.cell-bottom {
      grid-area: 7 / 2 / 8 / 3;

      @include mq-max-phone {
        grid-area: 13 / 2 / 14 / 8;
        justify-content: center;
        transform: none;

        .button-circle {
          display: none;
        }
      }
    }

    .cell-title {
      color: var(--dark);
      text-transform: uppercase;
      position: relative;
      @include flex(row, min-max(8, 16));
      align-items: center;

      &::before {
        content: "";
        position: relative;
        width: 32px;
        height: 1px;
        background-color: var(--dark);
      }
    }
  }

  .cell-circle-button {
    display: none;
    @include mq-max-phone {
      grid-area: 12 / 5 / 13 / 7;
      display: flex;
      .b_text {
        display: none;
      }
    }
  }

  .decor-cell {
    grid-area: 1 / 6 / 2 / 7;
    justify-self: end;

    @include mq-max-phone {
      display: none;
    }

    span {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      @include use-font(var(--gothamBold), min-max(12), 700, 100%, 0.4em);
      margin-top: -0.4em;
      color: var(--mid-grey);
      text-transform: uppercase;
    }
  }

  .section-button {
    grid-area: 10 / 4 / 11 / 5;
    padding-top: min-max(40, 96);

    @include mq-max-phone {
      grid-area: 14 / 2 / 15 / 8;
      padding: 0;
    }
  }
}
