@import "modern-normalize/modern-normalize.css";
@import "swiper/swiper.css";
@import "swiper/modules/scrollbar.css";
:root {
  --white: #ffffff;
  --yellow: #fffbf4;
  --air-grey: #f7f7f8;
  --light-grey: #ebedef;
  --light-mid-grey: #e1e3e4;
  --silver-grey: #d3d6d8;
  --mid-grey: #b7babc;
  --dark-grey: #8b8d8f;
  --dark: #0a3576;
  --light-blue: #708ab2;
  --bg: #fffbf4;
  --error: #f23a5b;
  --error-bg: #ffe4e9;
  --gradient: linear-gradient(
    133.5deg,
    #3d54a4 2.45%,
    #7789ea 50.45%,
    #94daea 98.45%
  );
  --cubic-bezier: cubic-bezier(0, 0.49, 0.25, 1);
  --transition: 0.3s ease;
  --transition-slow: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  --gotham: "Gotham Pro", sans-serif;
  --gothamMedium: "Gotham Pro Medium", sans-serif;
  --gothamBold: "Gotham Pro Bold", sans-serif;
}

@font-face {
  font-family: "Gotham Pro";
  src: local("Gotham Pro"), url("../fonts/GothamPro.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Pro Medium";
  src: local("Gotham Pro Medium"), url("../fonts/GothamPro-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Pro Bold";
  src: local("Gotham Pro Bold"), url("../fonts/GothamPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
html,
p {
  font-family: var(--gotham);
  font-size: clamp(12px, 0.7291666667vw, 14px);
  font-weight: 400;
  line-height: 160%;
  letter-spacing: normal;
}

p {
  color: var(--dark-grey);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h3 {
  font-family: var(--gothamBold);
  font-size: clamp(14px, 0.8333333333vw, 16px);
  font-weight: 700;
  line-height: 122%;
  letter-spacing: normal;
}

html,
body {
  height: 100vh;
  width: 100vw;
  overflow-x: clip;
  overflow-y: clip;
  scrollbar-width: none;
}
html ::-webkit-scrollbar,
body ::-webkit-scrollbar {
  width: 0;
}

html.loaded {
  overflow-y: auto;
  height: auto;
}
html.loaded body {
  position: relative;
  overflow-y: auto;
  height: auto;
}
html.loaded .body-texture {
  opacity: 1;
}

.body-texture {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("../img/pattern.webp") center/clamp(156px, 11.25vw, 216px) repeat;
  mix-blend-mode: multiply;
  transition: opacity 0.5s ease;
  z-index: 2;
  user-select: none;
  pointer-events: none;
}

.body-bg {
  position: fixed;
  user-select: none;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

main {
  visibility: hidden;
  opacity: 0;
}

section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1920px;
  position: relative;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-embed {
  display: flex;
  align-items: center;
  justify-content: center;
}
.svg-embed svg {
  width: 100%;
  height: 100%;
}

.img-contain {
  overflow: hidden;
}
.img-contain img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-cover {
  overflow: hidden;
}
.img-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

[data-fade=up] {
  opacity: 0;
  transform: translateY(50px);
}

[data-faded] {
  visibility: hidden;
  transform: translateY(75px);
}

[data-fade=in] {
  opacity: 0;
}

[data-cover-section] {
  clip-path: inset(50%);
}

[data-section-title] .word,
[data-reveal-text] .word {
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
[data-section-title] .char,
[data-reveal-text] .char {
  position: relative;
  display: inline-block;
}

[data-img-reveal] {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
[data-img-reveal] > div {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: url("../img/pattern.webp") center/clamp(200px, 13.3333333333vw, 256px) repeat;
  background-blend-mode: multiply;
  z-index: 9999;
  overflow: hidden;
}
.preloader__logo {
  width: clamp(120px, 13.3333333333vw, 256px);
  aspect-ratio: 256/174;
  visibility: hidden;
}
.preloader__logo path {
  fill: #c8c8c8;
  stroke: #c8c8c8;
}
.preloader__inner {
  display: flex;
  flex-direction: column;
  gap: clamp(30px, 2.34375vw, 45px);
  transform: translateY(clamp(100px, 6.5104166667vw, 125px));
  overflow: hidden;
}
.preloader__info {
  text-align: center;
}
.preloader__heading {
  font-family: var(--gothamBold);
  font-size: clamp(30px, 4.0104166667vw, 77px);
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0.3em;
  color: var(--dark);
  visibility: hidden;
  transform: translateY(5vh);
}
.preloader__subtitle {
  font-family: var(--gothamBold);
  font-size: clamp(16px, 0.9375vw, 18px);
  font-weight: 700;
  line-height: 130%;
  letter-spacing: normal;
  text-transform: uppercase;
  width: clamp(250px, 18.75vw, 360px);
  margin: clamp(10px, 1.0416666667vw, 20px) auto 0;
  color: var(--mid-grey);
  visibility: hidden;
  transform: translateY(5vh);
}

.button-circle {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: clamp(10px, 1.6666666667vw, 32px);
  align-items: center;
  justify-content: center;
  width: max-content;
  height: clamp(56px, 4.1666666667vw, 80px);
  white-space: nowrap;
  transition: var(--transition);
}
.button-circle .b_text {
  font-family: var(--gothamBold);
  font-size: clamp(12px, 0.7291666667vw, 14px);
  font-weight: 700;
  line-height: 160%;
  letter-spacing: normal;
  text-transform: uppercase;
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  transition: var(--transition);
}
.button-circle .b_icon {
  width: clamp(56px, 4.1666666667vw, 80px);
  height: clamp(56px, 4.1666666667vw, 80px);
}
.button-circle .b_icon .rotate,
.button-circle .b_icon .play {
  transition: var(--transition);
  transform-origin: 50% 50%;
}
.button-circle:hover .b_text {
  transform: translateX(clamp(10px, 0.8333333333vw, 16px));
}
.button-circle:hover .b_icon .rotate {
  transform: rotate(45deg) scale(0.8);
}
.button-circle:hover .b_icon .play {
  scale: 1.4;
  transform-origin: 50% 50%;
}

.button-arrow {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: max-content;
  height: clamp(56px, 3.3333333333vw, 64px);
  transition: var(--transition);
}
.button-arrow .b_icon {
  transition: var(--transition);
  width: clamp(56px, 3.3333333333vw, 64px);
  height: clamp(56px, 3.3333333333vw, 64px);
}
.button-arrow .b_text {
  font-family: var(--gothamBold);
  font-size: clamp(12px, 0.7291666667vw, 14px);
  font-weight: 700;
  line-height: 100%;
  letter-spacing: normal;
  text-transform: uppercase;
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  transition: var(--transition);
}
.button-arrow:hover .b_text {
  transform: translateX(clamp(8px, 0.5208333333vw, 10px));
}
.button-arrow:hover .b_icon {
  transform: rotate(45deg) scale(0.8);
  transform-origin: 50% 50%;
}

.button-phone {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  width: clamp(64px, 6.6666666667vw, 128px);
  height: clamp(64px, 6.6666666667vw, 128px);
  transition: var(--transition);
}
.button-phone:hover {
  scale: 0.8;
}
.button-phone .text-rotate {
  transform-origin: 50% 50%;
  animation: rotate 10s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slide-nav {
  width: clamp(48px, 2.9166666667vw, 56px);
  height: clamp(48px, 2.9166666667vw, 56px);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity var(--transition);
}
.slide-nav.next-slide {
  transform: rotate(180deg);
}
.slide-nav:hover svg path {
  fill: url("#gradient-arrow");
}
.slide-nav.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.modal-close {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(48px, 2.9166666667vw, 56px);
  height: clamp(48px, 2.9166666667vw, 56px);
  position: absolute;
  right: clamp(4px, 0.4166666667vw, 8px);
  top: clamp(4px, 0.4166666667vw, 8px);
}
.modal-close .icon-close {
  width: clamp(16px, 1.25vw, 24px);
  height: clamp(16px, 1.25vw, 24px);
}
.modal-close .icon-close path {
  fill: var(--dark-grey);
}

.fullscreen-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  visibility: hidden;
}

.modal-gallery__content {
  width: clamp(300px, 44.2708333333vw, 850px);
  aspect-ratio: 850/774;
  position: relative;
}
@media (max-width: 768px) {
  .modal-gallery__content {
    width: 95vw;
  }
}
.modal-gallery .swiper {
  width: 80%;
  height: 100%;
  z-index: 2;
}
@media (max-width: 768px) {
  .modal-gallery .swiper {
    width: 100%;
  }
}
.modal-gallery .slider-controls {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
@media (max-width: 768px) {
  .modal-gallery .slider-controls {
    top: calc(100% + 60px);
    align-items: end;
    justify-content: center;
    gap: 24px;
  }
}

.modal-video__content {
  width: clamp(625.6px, 40.7291666667vw, 782px);
  height: clamp(352px, 22.9166666667vw, 440px);
  position: relative;
}
.modal-video__content video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-contact__content {
  position: relative;
  height: 95vh;
  padding: clamp(64px, 4.1666666667vw, 80px) clamp(32px, 3.3333333333vw, 64px) clamp(24px, 1.6666666667vw, 32px) clamp(32px, 3.3333333333vw, 64px);
  background: url("../img/pattern.webp") center/clamp(200px, 13.3333333333vw, 256px) repeat;
}
.modal-contact .modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: clamp(12px, 0.8333333333vw, 16px);
  align-items: center;
  scrollbar-width: none;
  overflow-y: scroll;
}
.modal-contact .modal-body ::-webkit-scrollbar {
  width: 0;
}
.modal-contact .modal-body > * {
  flex: none;
}
.modal-contact .modal__title {
  font-family: var(--gothamMedium);
  font-size: clamp(14px, 1.25vw, 24px);
  font-weight: 500;
  line-height: 112%;
  letter-spacing: normal;
  color: var(--dark);
  text-transform: uppercase;
  text-align: center;
  width: 60%;
}
.modal-contact__form {
  display: flex;
  flex-direction: column;
  gap: clamp(16px, 1.25vw, 24px);
}
.modal-contact__form .form-select {
  margin: clamp(12px, 0.8333333333vw, 16px) auto 0;
  display: flex;
  flex-direction: row;
  gap: clamp(24px, 1.6666666667vw, 32px);
  align-items: center;
  justify-content: center;
}
.modal-contact__form .form-select button {
  outline: none;
  background: none;
  color: var(--dark-grey);
  font-family: var(--gothamMedium);
  border: none;
  padding-bottom: 4px;
  cursor: pointer;
}
.modal-contact__form .form-select button.active {
  color: var(--dark);
  border-bottom: 2px solid var(--dark);
  pointer-events: none;
}
.modal-contact__form .form-body {
  display: none;
}
.modal-contact__form .form-body.active {
  display: block;
}
.modal-contact__form .form-body label {
  color: var(--dark-grey);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-family: var(--gothamMedium);
  font-size: clamp(12px, 0.7291666667vw, 14px);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: normal;
  margin-top: clamp(16px, 1.25vw, 24px);
  position: relative;
}
.modal-contact__form .form-body label span {
  width: 100%;
}
.modal-contact__form .form-body label:first-of-type {
  margin-top: 0;
}
.modal-contact__form .form-body label.file-upload {
  cursor: pointer;
  flex-direction: row-reverse;
  width: max-content;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  margin-left: auto;
}
.modal-contact__form .form-body label.file-upload svg {
  flex: none;
}
.modal-contact__form .form-body label.error .tip {
  visibility: visible;
}
.modal-contact__form .form-body label.error input,
.modal-contact__form .form-body label.error textarea {
  background-color: var(--error-bg);
  color: var(--error);
}
.modal-contact__form .form-body input,
.modal-contact__form .form-body textarea {
  width: 100%;
  margin-top: 8px;
  padding: 12px 16px;
  background-color: var(--light-grey);
  resize: none;
  border: none;
  outline: none;
  border-radius: 8px;
  color: var(--dark);
}
.modal-contact__form .form-body input::placeholder,
.modal-contact__form .form-body textarea::placeholder {
  color: var(--dark-grey);
}
.modal-contact__form .form-body textarea {
  height: 100px;
}
.modal-contact__form .form-body input[type=file] {
  display: none;
}
.modal-contact__form .form-body .tip {
  position: absolute;
  width: 100%;
  height: 0.8333333333vw;
  left: 0;
  bottom: -1.25vw;
  font-family: var(--gotham);
  color: var(--error);
  text-align: left;
  visibility: hidden;
}
.modal-contact .form-contacts {
  display: flex;
  flex-direction: column;
  gap: clamp(6px, 0.4166666667vw, 8px);
  align-items: center;
}
.modal-contact__phone, .modal-contact__email {
  color: var(--dark);
  font-family: var(--gothamMedium);
  font-size: clamp(12px, 0.7291666667vw, 14px);
  font-weight: 500;
  line-height: 160%;
  letter-spacing: normal;
}
.modal-contact__phone:hover, .modal-contact__email:hover {
  background: var(--opacity);
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.modal-contact__phone {
  font-family: var(--gothamBold);
}
.modal-contact__social-icon {
  display: flex;
  flex-direction: row;
  gap: clamp(16px, 1.25vw, 24px);
  justify-content: space-around;
}
.modal-contact .social-item {
  width: clamp(24px, 1.6666666667vw, 32px);
  height: clamp(24px, 1.6666666667vw, 32px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-contact .social-item svg {
  width: 100%;
  height: 100%;
}
.modal-contact__copyright {
  color: var(--mid-grey);
  font-family: var(--gotham);
  font-size: clamp(12px, 0.7291666667vw, 14px);
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.02em;
  text-align: center;
}
.modal-contact__img {
  width: 100%;
  aspect-ratio: 276/58;
  background: url("../img/modal-contact.webp") center/cover no-repeat;
}

.modal-info__content {
  width: 310px;
  background-color: var(--white);
  padding: clamp(32px, 2.0833333333vw, 40px);
  display: flex;
  flex-direction: column;
  gap: clamp(16px, 1.25vw, 24px);
  align-items: center;
  text-align: center;
}
.modal-info .icon {
  display: none;
}
.modal-info .icon.active {
  display: block;
}
.modal-info .modal__title {
  font-family: var(--gothamMedium);
  font-size: clamp(14px, 0.8333333333vw, 16px);
  font-weight: 500;
  line-height: 112%;
  letter-spacing: normal;
  text-transform: uppercase;
  color: var(--dark);
}

.scroll-bar {
  display: flex;
  justify-content: center;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.scroll-bar .scroll-track {
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  background: var(--silver-grey);
  border-radius: 2px;
  transition: transform 0.1s linear;
}
.scroll-bar .scroll-track:hover {
  cursor: grab;
}
.scroll-bar.body-scroll {
  height: calc(100vh - clamp(60px, 4.1666666667vw, 80px));
  width: 4px;
  bottom: clamp(12px, 1.25vw, 24px);
  right: clamp(12px, 1.25vw, 24px);
  border-radius: 2px;
  background: rgba(192, 195, 204, 0.3);
}
.scroll-bar.div-scroll {
  height: 100%;
  width: 2px;
  right: 0;
  top: 0;
  background-color: var(--gray-22-opacity);
  border-radius: 20px;
}
.scroll-bar.visible {
  opacity: 1;
  z-index: 9999;
  pointer-events: all;
}
.scroll-bar:hover .scroll-track {
  opacity: 1;
  cursor: grab;
}
.scroll-bar.is-dragging {
  cursor: grabbing;
}
.scroll-bar.is-dragging .scroll-track {
  opacity: 1;
  transition: none;
}

[data-scroller] {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}
[data-scroller] ::-webkit-scrollbar {
  width: 0;
}

[data-scroller-wrapper] {
  position: relative;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.custom-pointer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.6666666667vw;
  height: 6.6666666667vw;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(52, 71, 136, 0.3);
  backdrop-filter: blur(4px);
  font-family: var(--gothamBold);
  text-transform: uppercase;
  color: var(--white);
  font-size: 0.625vw;
  pointer-events: none;
  visibility: hidden;
}

[data-reveal-text] {
  opacity: 0;
}
[data-reveal-text] .word {
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
[data-reveal-text] .char {
  position: relative;
  display: inline-block;
  transform: translateY(100%);
  opacity: 0;
  line-height: 1.1em;
}
[data-reveal-text].splitting {
  line-height: inherit !important;
}

[data-fade=up] {
  opacity: 0;
  transform: translateY(50%);
}

[data-fade=in] {
  opacity: 0;
}

[data-cover-section] {
  clip-path: inset(50%);
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: clamp(24px, 1.6666666667vw, 32px) clamp(24px, 3.3333333333vw, 64px);
  pointer-events: none;
  z-index: 1000;
  visibility: hidden;
}
header .header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}
header .header-logo {
  width: 143px;
  height: 32px;
  pointer-events: all;
  z-index: 2;
}
header .header-logo .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
header .header-logo .logo svg {
  width: 100%;
  height: 100%;
}
header .header-menu {
  display: flex;
  flex-direction: row;
  gap: clamp(25.6px, 1.6666666667vw, 32px);
  align-items: center;
  margin-left: clamp(107.2px, 6.9791666667vw, 134px);
}
@media (max-width: 1024px) {
  header .header-menu {
    display: none;
  }
}
header .header-menu a {
  font-family: var(--gothamBold);
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: normal;
  pointer-events: all;
  color: var(--mid-grey);
  transition: color var(--transition);
}
header .header-menu a:hover {
  color: var(--dark);
}
header .header-phone {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-left: clamp(107.2px, 6.9791666667vw, 134px);
  pointer-events: all;
}
@media (max-width: 1024px) {
  header .header-phone {
    display: none;
  }
}
header .header-phone .icon {
  width: clamp(19.2px, 1.25vw, 24px);
  height: clamp(19.2px, 1.25vw, 24px);
}
header .header-phone .icon path {
  transition: fill 0.3s ease-in-out;
}
header .header-phone .text {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
header .header-phone .text > span {
  font-family: var(--gothamMedium);
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: normal;
  transition: opacity var(--transition);
  position: absolute;
}
header .header-phone .text .nohover {
  color: var(--dark);
}
header .header-phone .text .hover {
  background: var(--gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
}
header .header-phone:hover .text .hover {
  opacity: 1;
}
header .header-phone:hover .icon path {
  fill: url("#gradient-arrow");
}
header .header-address {
  width: 56px;
  height: 56px;
  pointer-events: all;
  position: relative;
}
header .header-address::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid var(--light-grey);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  header .header-address {
    display: none;
  }
}
header .header-address:hover::after {
  transform: scale(1.5);
  opacity: 0;
}
header .header-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 76px;
  align-items: center;
  background-color: var(--dark);
  z-index: 0;
  transform: translateX(100%);
  transition: transform var(--transition);
  pointer-events: all;
  padding: 148px 80px 20px;
  overflow-y: auto;
  text-align: center;
}
header .header-mobile .mobile-menu {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
}
header .header-mobile .mobile-menu a {
  color: var(--white);
  font-family: var(--gothamMedium);
  font-size: 24px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: normal;
  text-transform: uppercase;
}
header .header-mobile .mobile-info {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}
header .header-mobile .mobile-info__contacts {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;
}
header .header-mobile .mobile-info__contacts .contacts-heading {
  font-family: var(--gothamMedium);
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: normal;
  color: var(--light-blue);
  text-transform: uppercase;
}
header .header-mobile .mobile-info__contacts a {
  font-family: var(--gothamMedium);
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: normal;
  color: var(--white);
}
header .header-mobile .mobile-info__contacts .working-hours span {
  font-family: var(--gothamMedium);
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: normal;
}
header .header-mobile .mobile-info__contacts .working-hours .day {
  color: var(--light-blue);
}
header .header-mobile .mobile-info__contacts .working-hours .time {
  color: var(--white);
}
header .header-mobile .mobile-info__socials {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
header .header-mobile .mobile-info__socials a {
  width: 24px;
  height: 24px;
}
header .header-mobile .mobile-info__socials a svg path {
  fill: var(--light-blue);
}
header .header-mobile .mobile-copyright {
  font-family: var(--gotham);
  font-size: 10px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: normal;
  color: var(--light-blue);
}
header .menu-toggle {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 8px;
  display: none;
  pointer-events: all;
  width: 48px;
  height: 48px;
  z-index: 2;
}
header .menu-toggle .icon-open {
  width: 20px;
  height: 12px;
  display: flex;
}
header .menu-toggle .icon-close {
  width: 16px;
  height: 16px;
  display: none;
}
@media (max-width: 1024px) {
  header .menu-toggle {
    display: flex;
  }
}
header.menu-opened .header-mobile {
  transform: none;
}
header.menu-opened .header-logo path {
  fill: var(--white);
}
header.menu-opened .menu-toggle .icon-open {
  display: none;
}
header.menu-opened .menu-toggle .icon-close {
  display: flex;
}

aside {
  position: fixed;
  right: 0;
  top: clamp(120px, 6.7708333333vw, 130px);
  padding-right: clamp(24px, 3.3333333333vw, 64px);
  pointer-events: none;
  z-index: 2;
  visibility: hidden;
}
@media (max-width: 1024px) {
  aside {
    display: none;
  }
}
aside .socials {
  width: 56px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
aside .socials a {
  width: 24px;
  height: 24px;
  padding: 2px;
  pointer-events: all;
}
aside .socials a svg path {
  transition: fill var(--transition);
}
aside .socials a:hover svg path {
  fill: var(--dark);
}

footer {
  width: 100%;
  padding: clamp(0px, 5vw, 96px) 0;
}
@media (max-width: 1024px) {
  footer {
    padding: 0;
  }
}
footer .container {
  display: flex;
  flex-direction: row;
  gap: clamp(0px, 8.3333333333vw, 160px);
  justify-content: space-between;
  padding-left: clamp(32px, 8.3333333333vw, 160px);
}
@media (max-width: 1024px) {
  footer .container {
    flex-direction: column-reverse;
    padding-left: 0;
  }
}
footer .footer-info {
  flex: none;
  width: clamp(375px, 36.4583333333vw, 700px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: clamp(32px, 5vw, 96px);
  row-gap: clamp(32px, 5vw, 96px);
}
@media (max-width: 1024px) {
  footer .footer-info {
    width: auto;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
  }
}
footer .footer-logo {
  grid-area: 1/1/2/3;
}
@media (max-width: 1024px) {
  footer .footer-logo {
    display: none;
  }
}
footer .footer-phones {
  grid-area: 2/1/3/2;
}
footer .footer-address {
  grid-area: 2/2/3/3;
}
footer .footer-bottom {
  grid-area: 3/1/4/2;
}
@media (max-width: 1024px) {
  footer .footer-bottom {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
  }
}
footer .footer-menu {
  grid-area: 3/2/4/3;
}
footer .footer-contacts {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (max-width: 1024px) {
  footer .footer-contacts {
    text-align: center;
    align-items: center;
  }
}
footer .footer-contacts__heading {
  font-family: var(--gothamBold);
  font-size: 12px;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: normal;
  text-transform: uppercase;
  color: var(--mid-grey);
}
footer .footer-contacts a {
  font-family: var(--gothamMedium);
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: normal;
  color: var(--dark);
}
@media (max-width: 1024px) {
  footer .footer-contacts a {
    font-size: 12px;
  }
}
footer .footer-contacts .contacts-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (max-width: 1024px) {
  footer .footer-contacts .contacts-list {
    align-items: center;
  }
}
footer .footer-contacts .working-hours {
  font-family: var(--gothamMedium);
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: normal;
}
footer .footer-contacts .working-hours .day {
  color: var(--mid-grey);
  text-transform: uppercase;
}
footer .footer-contacts .working-hours .time {
  color: var(--dark);
}
footer .footer-socials {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
footer .footer-socials a {
  width: 24px;
  height: 24px;
}
@media (max-width: 1024px) {
  footer .footer-socials {
    gap: 24px;
  }
}
footer .footer-copyright {
  margin-top: 56px;
  font-family: var(--gothamMedium);
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: normal;
  color: var(--dark);
}
@media (max-width: 1024px) {
  footer .footer-copyright {
    text-align: center;
    margin: 0;
  }
}
footer .footer-menu {
  display: flex;
  flex-direction: row;
  gap: clamp(16px, 1.25vw, 24px);
  align-items: flex-end;
}
footer .footer-menu a {
  font-family: var(--gothamMedium);
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: normal;
  color: var(--mid-grey);
}
footer .footer-map {
  width: 100%;
  height: auto;
  background-color: var(--air-grey);
}
@media (max-width: 1024px) {
  footer .footer-map {
    width: 100%;
    aspect-ratio: 375/300;
  }
}

section.intro {
  min-height: 100vh;
}
@media (max-width: 768px) {
  section.intro .container {
    display: flex;
    flex-direction: column;
    padding: 0 65px 0;
  }
}
section.intro .section-img {
  z-index: 1;
  visibility: hidden;
  transform: translateY(10%);
}
section.intro .section-img--mobile {
  display: none;
  width: auto;
  aspect-ratio: 1/1;
  background: url("../img/keyvisual-mobile.webp") center/contain no-repeat;
}
@media (max-width: 768px) {
  section.intro .section-img--mobile {
    display: flex;
    order: 2;
    margin-left: -65px;
    margin-right: -65px;
  }
}
section.intro .section-img--desktop {
  position: absolute;
  right: 0;
  top: -15%;
  width: clamp(375px, 61.0416666667vw, 1172px);
  aspect-ratio: 1/1;
  background: url("../img/keyvisual-desktop.webp") center/contain no-repeat;
}
@media (max-width: 768px) {
  section.intro .section-img--desktop {
    display: none;
  }
}
section.intro .section-content {
  padding-top: clamp(88px, 9.7916666667vw, 188px);
  display: flex;
  flex-direction: column;
  gap: clamp(53.6px, 3.4895833333vw, 67px);
  z-index: 2;
}
@media (max-width: 768px) {
  section.intro .section-content {
    gap: 0;
  }
}
section.intro .section-button {
  margin-left: clamp(32px, 9.6354166667vw, 185px);
  visibility: hidden;
  transform: translateY(50%);
}
@media (max-width: 768px) {
  section.intro .section-button {
    order: 1;
    margin: 0;
  }
  section.intro .section-button .b_text {
    display: none;
  }
}
section.intro .section-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: clamp(32px, 10.4166666667vw, 200px);
  width: max-content;
  white-space: nowrap;
  z-index: 2;
}
@media (max-width: 768px) {
  section.intro .section-title {
    margin: 0;
    order: 0;
  }
}
section.intro .section-title span {
  font-family: var(--gothamMedium);
  font-size: clamp(32px, 4.1666666667vw, 80px);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: normal;
  color: var(--dark);
  visibility: hidden;
  transform: translateY(50%);
}
section.intro .section-title span:nth-child(2) {
  align-self: center;
}
section.intro .section-title span:nth-child(3) {
  align-self: flex-end;
}
section.intro .section-bottom {
  display: flex;
  flex-direction: row;
  gap: clamp(24px, 3.3333333333vw, 64px);
  width: clamp(243px, 34.7395833333vw, 667px);
}
@media (max-width: 1024px) {
  section.intro .section-bottom {
    margin-left: clamp(32px, 10.4166666667vw, 200px);
    padding-bottom: 48px;
  }
}
@media (max-width: 768px) {
  section.intro .section-bottom {
    margin: 0;
    order: 3;
  }
}
section.intro .section-bottom__img {
  flex: none;
  width: clamp(100px, 17.8125vw, 342px);
  aspect-ratio: 342/321;
  visibility: hidden;
  transform: translateY(50%);
}
@media (max-width: 1024px) {
  section.intro .section-bottom__img {
    display: none;
  }
}
section.intro .section-bottom__info {
  flex: none;
  display: flex;
  flex-direction: column;
  gap: clamp(24px, 2.0833333333vw, 40px);
  width: clamp(243px, 13.28125vw, 255px);
}
section.intro .section-bottom__info-text, section.intro .section-bottom__info-button {
  visibility: hidden;
  transform: translateY(50%);
}

section.services {
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 1024px) {
  section.services {
    height: auto;
  }
}
section.services .container {
  height: 100%;
  display: grid;
  grid-template-columns: 17.78% 28.71% 43.53%;
  grid-column-gap: clamp(40px, 5vw, 96px);
  grid-row-gap: clamp(24px, 2.0833333333vw, 40px);
}
@media (max-width: 1024px) {
  section.services .container {
    padding: 88px 65px 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }
}
section.services .section-slider {
  height: 100%;
  display: grid;
  align-content: end;
}
@media (max-width: 1024px) {
  section.services .section-slider {
    height: auto;
  }
}
section.services .section-slider .swiper {
  overflow-y: visible;
  overflow-x: clip;
}
@media (max-width: 1024px) {
  section.services .section-slider .swiper {
    overflow-y: clip;
  }
}
section.services .section-slider .swiper-slide {
  user-select: none;
  transition: 0.3s ease;
}
section.services .section-slider--small {
  grid-area: 1/1/4/2;
  align-content: end;
}
@media (max-width: 1024px) {
  section.services .section-slider--small {
    display: none;
  }
}
section.services .section-slider--small .swiper {
  height: clamp(341px, 21.7708333333vw, 418px);
  width: 100%;
  aspect-ratio: 342/418;
}
section.services .section-slider--small .swiper-slide {
  width: 100%;
  aspect-ratio: 342/418;
}
section.services .section-slider--large {
  grid-area: 1/2/3/3;
}
section.services .section-slider--large .swiper {
  aspect-ratio: 552/774;
  height: clamp(341px, 40.3125vw, 774px);
  width: 100%;
}
section.services .section-slider--large .swiper-slide {
  width: 100%;
  aspect-ratio: 552/774;
}
@media (max-width: 1024px) {
  section.services .section-slider--large {
    width: 100%;
    height: auto;
  }
  section.services .section-slider--large .swiper {
    overflow: hidden;
  }
}
section.services .section-list {
  grid-area: 1/3/3/4;
  display: grid;
  align-content: end;
}
section.services .section-list .slider-toggles {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: max-content;
}
section.services .section-list .slider-toggles .item {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: var(--gothamMedium);
  font-size: clamp(24px, 3.125vw, 60px);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: left;
  color: var(--light-grey);
  transition: color var(--transition);
}
section.services .section-list .slider-toggles .item-line {
  width: 100%;
  height: 1px;
  background-color: var(--silver-grey);
  position: relative;
  opacity: 0;
  transition: opacity var(--transition);
}
@media (max-width: 1024px) {
  section.services .section-list .slider-toggles .item-line {
    display: none;
  }
}
section.services .section-list .slider-toggles .item-line__track {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--dark);
  transform-origin: left;
  transform: scaleX(0%);
}
section.services .section-list .slider-toggles .item:hover, section.services .section-list .slider-toggles .item.active {
  color: var(--dark);
}
section.services .section-list .slider-toggles .item:hover .item-line, section.services .section-list .slider-toggles .item.active .item-line {
  opacity: 1;
}
section.services .section-button--top {
  position: absolute;
  right: clamp(64px, 5vw, 96px);
  top: clamp(64px, 5vw, 96px);
  z-index: 2;
}
@media (max-width: 1024px) {
  section.services .section-button--top {
    display: none;
  }
}
section.services .section-button--bottom {
  grid-area: 3/3/4/4;
  padding-bottom: clamp(24px, 3.125vw, 60px);
  align-self: flex-start;
}

section.about .container {
  padding: clamp(48px, 10.4166666667vw, 200px) clamp(32px, 8.3333333333vw, 160px);
}
@media (max-width: 768px) {
  section.about .container {
    padding: 104px 24px 76px;
  }
}
section.about .grid {
  height: clamp(1396px, 137.7604166667vw, 2645px);
  display: grid;
  grid-template-columns: 20.375% 24.3125% 8.9375% 24.375% 9% 13%;
  grid-template-rows: 8.963% 10.836% 5.678% 21.135% 7.787% 6.002% 10.547% 5.192% 12.415% 11.445%;
}
@media (max-width: 768px) {
  section.about .grid {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 5.73% 5.73% 3.44% 6.81% 4.08% 22.56% 3.44% 6.81% 3.44% 5.8% 5.44% 4.01% 18.7% 4.01%;
    column-gap: 8px;
  }
}
section.about .grid .img-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
section.about .grid .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.about .section-heading {
  grid-area: 1/1/2/3;
  display: flex;
}
@media (max-width: 768px) {
  section.about .section-heading {
    grid-area: 1/1/2/6;
  }
}
section.about .section-heading div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
section.about .section-title {
  color: var(--dark);
  font-family: var(--gothamBold);
  font-size: clamp(24px, 4.1666666667vw, 80px);
  font-weight: 700;
  line-height: 100%;
  letter-spacing: clamp(7px, 1.25vw, 24px);
}
section.about .section-subtitle {
  font-family: var(--gothamBold);
  font-size: clamp(6px, 0.625vw, 12px);
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.15em;
  color: var(--mid-grey);
  width: 40%;
  text-align: center;
}
section.about .img-big.cell-top {
  grid-area: 2/1/5/3;
}
@media (max-width: 768px) {
  section.about .img-big.cell-top {
    grid-area: 2/1/6/5;
    margin-left: -24px;
  }
}
section.about .img-big.cell-bottom {
  grid-area: 6/4/10/6;
}
@media (max-width: 960px) {
  section.about .img-big.cell-bottom {
    grid-area: 6/4/10/7;
  }
}
@media (max-width: 768px) {
  section.about .img-big.cell-bottom {
    grid-area: 7/5/11/9;
    margin-right: -24px;
  }
}
section.about .img-middle.cell-top {
  grid-area: 1/4/3/5;
}
@media (max-width: 768px) {
  section.about .img-middle.cell-top {
    grid-area: 1/6/3/9;
    margin-right: -24px;
  }
}
section.about .img-middle.cell-bottom {
  grid-area: 9/2/11/3;
}
@media (max-width: 1024px) {
  section.about .img-middle.cell-bottom {
    grid-area: 10/2/11/3;
  }
}
@media (max-width: 768px) {
  section.about .img-middle.cell-bottom {
    grid-area: 10/1/13/4;
    margin-left: -24px;
  }
}
section.about .img-middle.cell-bottom .img-wrapper {
  aspect-ratio: 390/588;
}
section.about .img-small.cell-top {
  grid-area: 4/6/5/7;
}
section.about .img-small.cell-top .img-wrapper {
  height: auto;
}
@media (max-width: 768px) {
  section.about .img-small.cell-top {
    grid-area: 4/6/5/8;
  }
}
section.about .img-small.cell-bottom {
  grid-area: 7/1/8/2;
  padding-right: 30%;
}
@media (max-width: 768px) {
  section.about .img-small.cell-bottom {
    grid-area: 8/2/9/4;
    padding-right: 0;
  }
}
section.about .img-small .img-wrapper {
  aspect-ratio: 208/260;
}
section.about .text-cell {
  display: flex;
  flex-direction: column;
  gap: 24px;
  transform: translateY(-3.8em);
}
section.about .text-cell.cell-top {
  grid-area: 4/4/5/5;
}
@media (max-width: 768px) {
  section.about .text-cell.cell-top {
    grid-area: 6/2/7/8;
    justify-content: center;
    transform: none;
  }
}
section.about .text-cell.cell-bottom {
  grid-area: 7/2/8/3;
}
@media (max-width: 768px) {
  section.about .text-cell.cell-bottom {
    grid-area: 13/2/14/8;
    justify-content: center;
    transform: none;
  }
  section.about .text-cell.cell-bottom .button-circle {
    display: none;
  }
}
section.about .text-cell .cell-title {
  color: var(--dark);
  text-transform: uppercase;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: clamp(8px, 0.8333333333vw, 16px);
  align-items: center;
}
section.about .text-cell .cell-title::before {
  content: "";
  position: relative;
  width: 32px;
  height: 1px;
  background-color: var(--dark);
}
section.about .cell-circle-button {
  display: none;
}
@media (max-width: 768px) {
  section.about .cell-circle-button {
    grid-area: 12/5/13/7;
    display: flex;
  }
  section.about .cell-circle-button .b_text {
    display: none;
  }
}
section.about .decor-cell {
  grid-area: 1/6/2/7;
  justify-self: end;
}
@media (max-width: 768px) {
  section.about .decor-cell {
    display: none;
  }
}
section.about .decor-cell span {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-family: var(--gothamBold);
  font-size: clamp(9.6px, 0.625vw, 12px);
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.4em;
  margin-top: -0.4em;
  color: var(--mid-grey);
  text-transform: uppercase;
}
section.about .section-button {
  grid-area: 10/4/11/5;
  padding-top: clamp(40px, 5vw, 96px);
}
@media (max-width: 768px) {
  section.about .section-button {
    grid-area: 14/2/15/8;
    padding: 0;
  }
}

section.team {
  height: 100vh;
  padding: 100px 0;
  align-items: flex-start;
  user-select: none;
}
section.team .container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: clamp(16px, 1.25vw, 24px);
  padding: 0 clamp(24px, 3.125vw, 60px);
  transform: translateY(50%);
}
@media (max-width: 1024px) {
  section.team .container {
    transform: translateY(5%);
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  section.team .container {
    align-items: flex-start;
  }
}
section.team .section-gallery {
  grid-area: 1/4/2/7;
  width: clamp(240px, 16.1458333333vw, 310px);
  aspect-ratio: 263/373;
  display: grid;
  transform: translateX(-40%) scale(1.7);
  user-select: none;
}
section.team .section-gallery.top-hidden .top-gallery {
  opacity: 0;
}
@media (max-width: 1024px) {
  section.team .section-gallery {
    width: clamp(220px, 16.1458333333vw, 310px);
    transform: translateY(-10%);
  }
}
section.team .section-gallery .item {
  grid-column: 1;
  grid-row: 1;
  transform-origin: 80% 80%;
}
section.team .section-gallery .item.top-gallery {
  pointer-events: none;
  transition: opacity var(--transition);
}
section.team .section-gallery .item:nth-of-type(4) {
  transform: rotate(22deg) translate(7.5%, -7.5%);
  z-index: 1;
}
@media (max-width: 1024px) {
  section.team .section-gallery .item:nth-of-type(4) {
    transform: rotate(22deg);
  }
}
section.team .section-gallery .item:nth-of-type(3) {
  z-index: 2;
  transform: rotate(11deg) translate(5%, -5%);
}
@media (max-width: 1024px) {
  section.team .section-gallery .item:nth-of-type(3) {
    transform: rotate(11deg);
  }
}
section.team .section-gallery .item:nth-of-type(2) {
  z-index: 3;
}
section.team .section-gallery .swiper {
  overflow: visible;
}
section.team .section-gallery .slider-x {
  position: absolute;
  width: calc(100% + clamp(32px, 2.5vw, 48px));
  height: clamp(373px, 23.0208333333vw, 442px);
  z-index: 4;
  opacity: 0;
  transition: opacity var(--transition);
  pointer-events: none;
}
@media (max-width: 1024px) {
  section.team .section-gallery .slider-x {
    width: clamp(278px, 17.1354166667vw, 329px);
    transform: scale(0.8) translateY(20vh);
  }
}
section.team .section-gallery .slider-x .slide-x {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: clamp(373px, 23.0208333333vw, 442px);
  aspect-ratio: 288/441;
  padding-left: clamp(16px, 1.25vw, 24px);
  padding-right: clamp(16px, 1.25vw, 24px);
}
section.team .section-gallery .slider-x .slide-x .slider-y {
  flex: none;
  position: relative;
  width: 100%;
  aspect-ratio: 288/441;
  max-height: clamp(373px, 23.0208333333vw, 442px);
  transition: var(--transition);
}
@media (max-width: 1024px) {
  section.team .section-gallery .slider-x .slide-x .slider-y {
    overflow-y: clip;
  }
}
section.team .section-gallery .slider-x .slide-x .slider-y .slide-y {
  width: 100%;
  aspect-ratio: 432/612;
  height: clamp(373px, 23.0208333333vw, 442px);
}
section.team .section-gallery .slider-x .slide-x .slider-y .slide-y:not(:last-of-type) {
  margin-bottom: clamp(24px, 2.0833333333vw, 40px);
}
section.team .section-gallery .slider-x .slide-x .slider-y .slide-y::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity var(--transition);
  z-index: 2;
}
section.team .section-gallery .slider-x .slide-x .info {
  display: flex;
  flex-direction: column;
  gap: clamp(2px, 0.2083333333vw, 4px);
  transition: opacity var(--transition);
  pointer-events: none;
  opacity: 0;
}
section.team .section-gallery .slider-x .slide-x .info-name {
  text-transform: uppercase;
  color: var(--dark);
}
section.team .section-gallery .slider-x .slide-x:hover .slider-y {
  transform: scale(1.15);
  max-height: 100vh;
}
section.team .section-gallery .slider-x .slide-x:hover .slider-y .slide-y::after {
  opacity: 0 !important;
}
section.team .section-gallery .slider-x.visible {
  opacity: 1;
}
section.team .section-gallery .slider-x.visible .info {
  opacity: 1;
}
section.team .section-gallery .slider-x.active {
  pointer-events: all;
}
section.team .section-gallery .slider-x:hover .slider-y {
  transform: scale(0.8);
}
section.team .section-gallery .slider-x:hover .slider-y .slide-y::after {
  opacity: 1 !important;
}
section.team .section-gallery .slider-x:hover .info {
  opacity: 0;
}
section.team .section-content {
  grid-area: 1/9/2/12;
  display: flex;
  flex-direction: column;
  gap: clamp(16px, 1.25vw, 24px);
  justify-content: center;
}
section.team .section-title {
  font-family: var(--gothamMedium);
  font-size: clamp(40px, 3.75vw, 72px);
  font-weight: 500;
  line-height: 120%;
  letter-spacing: normal;
  color: var(--dark);
  text-transform: uppercase;
}
section.team .section-subtitle {
  width: 90%;
}
@media (max-width: 1024px) {
  section.team .section-subtitle {
    width: auto;
  }
}
section.team .slider-controls {
  position: fixed;
  width: 100%;
  max-width: 1920px;
  left: 0;
  right: 0;
  bottom: clamp(24px, 3.125vw, 60px);
  padding: 0 clamp(24px, 8.3333333333vw, 160px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transform: translateY(25vh);
  transition: var(--transition-slow);
}
section.team .slider-controls.active {
  opacity: 1;
  transform: translateY(0);
}
@media (max-width: 1024px) {
  section.team .slider-controls {
    display: none;
  }
}
section.team .slider-controls .arrows {
  display: flex;
  flex-direction: row;
  gap: clamp(8px, 0.8333333333vw, 16px);
}
section.team .slider-controls .slider-title {
  font-family: var(--gothamMedium);
  font-size: clamp(24px, 5vw, 96px);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: normal;
  text-transform: uppercase;
  color: var(--silver-grey);
}

section.partners {
  padding: clamp(40px, 6.6666666667vw, 128px) 0;
}
section.partners .swiper {
  width: 100%;
  overflow: visible;
}
section.partners .swiper-wrapper {
  transition-timing-function: linear;
}
section.partners .swiper-slide {
  width: 256px;
  height: 256px;
  user-select: none;
}
section.partners .swiper-slide .slide-item {
  transition: transform var(--transition);
}
section.partners .swiper-slide:hover .slide-item {
  transform: scale(1.2);
}

section.testimonials {
  padding: clamp(100px, 10.4166666667vw, 200px) 0 clamp(68px, 10.4166666667vw, 200px) clamp(24px, 8.3333333333vw, 160px);
}
@media (max-width: 1024px) {
  section.testimonials {
    padding: 100px 24px 60px 24px;
  }
}
section.testimonials .swiper {
  width: 100%;
  overflow: visible;
  position: relative;
}
section.testimonials .swiper-wrapper {
  gap: clamp(66px, 13.5416666667vw, 260px);
}
section.testimonials .swiper-slide {
  position: relative;
  width: clamp(243px, 40.625vw, 780px);
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: clamp(24px, 2.8645833333vw, 55px);
}
section.testimonials .swiper-slide .quote {
  position: absolute;
  width: clamp(64px, 6.5625vw, 126px);
  height: clamp(50px, 5.2083333333vw, 100px);
  top: 8px;
  left: 0;
  z-index: -1;
  transform: translateX(-60%);
}
section.testimonials .swiper-slide .text {
  font-family: var(--gotham);
  font-size: clamp(10px, 1.4583333333vw, 28px);
  font-weight: 400;
  line-height: 140%;
  letter-spacing: normal;
  text-transform: uppercase;
  color: var(--dark);
}
section.testimonials .swiper-slide .link {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
section.testimonials .swiper-slide .link .author {
  font-family: var(--gothamBold);
  font-size: clamp(10px, 0.625vw, 12px);
  font-weight: 700;
  line-height: 100%;
  letter-spacing: normal;
  text-transform: uppercase;
  display: grid;
}
section.testimonials .swiper-slide .link .author > span {
  transition: opacity var(--transition);
}
section.testimonials .swiper-slide .link .author .nohover {
  grid-area: 1/1/1/1;
  color: var(--mid-grey);
}
section.testimonials .swiper-slide .link .author .hover {
  opacity: 0;
  grid-area: 1/1/1/1;
  background: var(--gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
section.testimonials .swiper-slide .link .icon {
  width: clamp(16px, 1.25vw, 24px);
  height: clamp(16px, 1.25vw, 24px);
}
section.testimonials .swiper-slide .link .icon path {
  transition: fill var(--transition);
}
section.testimonials .swiper-slide .link:hover .author .nohover {
  opacity: 0;
}
section.testimonials .swiper-slide .link:hover .author .hover {
  opacity: 1;
}
section.testimonials .swiper-slide .link:hover .icon path {
  fill: url("#gradient-arrow");
}
section.testimonials .swiper-scrollbar {
  position: relative;
  margin-top: 60px;
  left: 0;
  right: 0;
  height: 2px;
}
@media (max-width: 1024px) {
  section.testimonials .swiper-scrollbar {
    display: none;
  }
}
section.testimonials .swiper-scrollbar-drag {
  background: var(--dark);
}