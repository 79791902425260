section.intro {
  // background-color: var(--bg);
  min-height: 100vh;

  .container {
    @include mq-max-phone {
      @include flex(column);
      padding: 0 65px 0;
    }
  }

  .section-img {
    z-index: 1;
    visibility: hidden;
    transform: translateY(10%);

    &--mobile {
      display: none;
      width: auto;
      aspect-ratio: 1/1;
      @include use-img(
        "../img/keyvisual-mobile.png",
        center,
        contain,
        no-repeat
      );

      @include mq-max-phone {
        display: flex;
        order: 2;
        margin-left: -65px;
        margin-right: -65px;
      }
    }

    &--desktop {
      position: absolute;
      right: 0;
      top: -15%;
      width: min-max(375, 1172);
      aspect-ratio: 1/1;
      @include use-img(
        "../img/keyvisual-desktop.png",
        center,
        contain,
        no-repeat
      );

      @include mq-max-phone {
        display: none;
      }
    }
  }

  .section-content {
    padding-top: min-max(88, 188);
    @include flex(column, min-max(67));
    z-index: 2;

    @include mq-max-phone {
      gap: 0;
    }
  }

  .section-button {
    margin-left: min-max(32, 185);
    visibility: hidden;
    transform: translateY(50%);

    @include mq-max-phone {
      order: 1;
      margin: 0;
      .b_text {
        display: none;
      }
    }
  }

  .section-title {
    @include flex(column, 4px);
    margin-left: min-max(32, 200);
    width: max-content;
    white-space: nowrap;
    z-index: 2;

    @include mq-max-phone {
      margin: 0;
      order: 0;
    }

    span {
      @include use-font(var(--gothamMedium), min-max(32, 80), 500);
      color: var(--dark);
      visibility: hidden;
      transform: translateY(50%);

      &:nth-child(2) {
        align-self: center;
      }

      &:nth-child(3) {
        align-self: flex-end;
      }
    }
  }

  .section-bottom {
    @include flex(row, min-max(24, 64));
    width: min-max(243, 667);

    @include mq-max-tablet {
      margin-left: min-max(32, 200);
      padding-bottom: 48px;
    }

    @include mq-max-phone {
      margin: 0;
      order: 3;
    }

    &__img {
      flex: none;
      width: min-max(100, 342);
      aspect-ratio: 342/321;
      visibility: hidden;
      transform: translateY(50%);

      @include mq-max-tablet {
        display: none;
      }
    }

    &__info {
      flex: none;
      @include flex(column, min-max(24, 40));
      width: min-max(243, 255);

      &-text, &-button {
        visibility: hidden;
        transform: translateY(50%);
      }
    }
  }
}
