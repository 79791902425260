section.partners {
  // background-color: var(--bg);
  padding: min-max(40, 128) 0;

  .swiper {
    width: 100%;
    overflow: visible;

    &-wrapper {
      transition-timing-function: linear;
    }

    &-slide {
      width: 256px;
      height: 256px;
      user-select: none;

      .slide-item {
        transition: transform var(--transition);
      }

      &:hover {
        .slide-item {
          transform: scale(1.2);
        }
      }
    }
  }
}
