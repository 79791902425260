section.testimonials {
  // background-color: var(--white);
  padding: min-max(100, 200) 0 min-max(68, 200) min-max(24, 160);

  @include mq-max-tablet {
    padding: 100px 24px 60px 24px;
  }

  .swiper {
    width: 100%;
    overflow: visible;
    position: relative;

    &-wrapper {
      gap: min-max(66, 260);
    }

    &-slide {
      position: relative;
      width: min-max(243, 780);
      user-select: none;
      @include flex(column, min-max(24, 55));

      .quote {
        position: absolute;
        width: min-max(64, 126);
        height: min-max(50, 100);
        top: 8px;
        left: 0;
        z-index: -1;
        transform: translateX(-60%);
      }

      .text {
        @include use-font(var(--gotham), min-max(10, 28), 400, 140%);
        text-transform: uppercase;
        color: var(--dark);
      }

      .link {
        @include flex(row, 8px);
        align-items: center;

        .author {
          @include use-font(var(--gothamBold), min-max(10, 12), 700);
          text-transform: uppercase;
          display: grid;

          & > span {
            transition: opacity var(--transition);
          }

          .nohover {
            grid-area: 1/1/1/1;
            color: var(--mid-grey);
          }

          .hover {
            opacity: 0;
            grid-area: 1/1/1/1;
            background: var(--gradient);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .icon {
          width: min-max(16, 24);
          height: min-max(16, 24);

          path {
            transition: fill var(--transition);
          }
        }

        &:hover {
          .author {
            .nohover {
              opacity: 0;
            }
            .hover {
              opacity: 1;
            }
          }

          .icon {
            path {
              fill: url("#gradient-arrow");
            }
          }
        }
      }
    }

    &-scrollbar {
      position: relative;
      margin-top: 60px;
      left: 0;
      right: 0;
      height: 2px;
      // width: 100% !important;
      // width: calc(100vw - 280px);

      @include mq-max-tablet {
        display: none;
      }

      &-drag {
        background: var(--dark);
      }
    }
  }
}
