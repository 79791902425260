@mixin team-slider {
  user-select: none;

  .swiper {
    overflow: visible;
  }

  .slider-x {
    position: absolute;
    width: calc(100% + min-max(32, 48));
    height: min-max(373, 442);
    z-index: 4;
    opacity: 0;
    transition: opacity var(--transition);
    pointer-events: none;

    @include mq-max-tablet {
      width: min-max(278, 329);
      transform: scale(0.8) translateY(20vh);
    }

    .slide-x {
      @include flex(column, 24px);
      height: min-max(373, 442);
      aspect-ratio: 288/441;
      padding-left: min-max(16, 24);
      padding-right: min-max(16, 24);

      .slider-y {
        flex: none;
        position: relative;
        width: 100%;
        aspect-ratio: 288/441;
        max-height: min-max(373, 442);
        transition: var(--transition);

        @include mq-max-tablet {
          overflow-y: clip;
        }

        .slide-y {
          width: 100%;
          aspect-ratio: 432/612;
          height: min-max(373, 442);

          &:not(:last-of-type) {
            margin-bottom: min-max(24, 40);
          }

          &::after {
            content: "";
            display: block;
            @include full-bg;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0;
            transition: opacity var(--transition);
            z-index: 2;
          }
        }
      }

      .info {
        @include flex(column, min-max(2, 4));
        transition: opacity var(--transition);
        pointer-events: none;
        opacity: 0;

        &-name {
          text-transform: uppercase;
          color: var(--dark);
        }
      }

      &:hover {
        .slider-y {
          transform: scale(1.15);
          max-height: 100vh;

          .slide-y::after {
            opacity: 0 !important;
          }
        }
      }
    }

    &.visible {
      opacity: 1;

      .info {
        opacity: 1;
      }
    }

    &.active {
      pointer-events: all;
    }

    &:hover {
      .slider-y {
        transform: scale(0.8);

        .slide-y::after {
          opacity: 1 !important;
        }
      }

      .info {
        opacity: 0;
      }
    }
  }
}
